/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"NF Vidžín"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" layout={"l13"} name={"nfvidzinuvod"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Nadační fond Vidžín"}>
              </Title>

              <Text className="text-box fs--20" content={"<span style=\"color: var(--color-custom-1);\">Nadační fond Vidžín je správcem a provozovatelem kulturní památky Vidžín. Naší prioritou je ochrana památky a její zachování, zpřístupnění a vhodné využívání tak, aby se podílela na rozvoji naší společnosti.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/834da6d5c91c4a3fb1b8dac716bf20fd_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/7361/834da6d5c91c4a3fb1b8dac716bf20fd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/834da6d5c91c4a3fb1b8dac716bf20fd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/834da6d5c91c4a3fb1b8dac716bf20fd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/834da6d5c91c4a3fb1b8dac716bf20fd_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" style={{"paddingTop":32,"paddingBottom":101}} name={"nfinformace"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--left fs--20" style={{"maxWidth":1166}} content={"<span style=\"color: var(--color-custom-1);\">Víme, že kulturní dědictví je jeden z pilířů identity národa. Bohatství České republiky je v historii a památkách. Proto by mělo být zájmem celé společnosti památky chránit. Kulturní památky jsou chráněny jako nedílná součást kulturního dědictví, svědectví dějin, významný činitel životního prostředí a nenahraditelné bohatství. Přispěním na účet č. ú. 298084365/0300 nám pomáháte tuto památku chránit a obnovovat. Jak funguje odečet daru kulturní památce ze základu daně? <br>Chcete vědět, kolik % si můžete odečíst z daní?</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"Fyzické osoby"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--20" style={{"maxWidth":650}} content={"<span style=\"color: var(--color-custom-1);\">Od základu daně mohou fyzické osoby (včetně zaměstnanců) odečíst hodnotu poskytnutých bezúplatných plnění, pokud jejich úhrnná hodnota ve zdaňovacím období (kalendářní rok) přesáhne 2 % ze základu daně anebo činí alespoň 1 000 Kč.\n<br><br>Osoby samostatně výdělečně činné (OSVČ) uplatňují odpočet darů z daní přímo ve svém daňovém přiznání. Zaměstnanci, jimž daňové vyrovnání zpracovává zaměstnavatel, předkládají potvrzení o poskytnutí daru své mzdové účtárně jednou ročně, nejpozději do poloviny února, a to za celý předchozí rok. <br>Snížení daňového základu se následně projeví v rámci daňového vyrovnání ve vyšší březnové výplatě.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"Právnické osoby"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--20" style={{"maxWidth":650,"paddingRight":7}} content={"<span style=\"color: var(--color-custom-1);\">Právnické osoby si mohou od základu daně odečíst hodnotu bezúplatných plnění maximálně do výše 10 % z již sníženého základu daně, pokud hodnota bezúplatného plnění činí alespoň 2 000 Kč.\n<br>Pro upřesnění uvádíme citaci pokynu Generálního finančního ředitelství č. G F Ř – D – 59 k jednotnému postupu při uplatňování některých ustanovení zákona č. 586/1992 Sb., o daních z příjmů:\n<br>“V případě, že 10% snížení základu daně činí méně než 2 000 Kč, bude při splnění podmínky minimální hodnoty bezúplatného plnění 2 000 Kč odpočítána pouze částka představující 10 % základu daně. Při posuzování minimální výše hodnoty daru je nutné přihlédnout ke způsobu darování.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":1270,"marginTop":63,"paddingLeft":53,"paddingRight":44}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"Jaké údaje musím vyplňovat kvůli potvrzení o daru a proč?"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--20" style={{"maxWidth":1168,"paddingRight":0,"paddingLeft":0}} content={"<span style=\"color: var(--color-custom-1);\">Abychom mohli vystavit a zaslat Vám <span style=\"font-weight: bold;\">potvrzení o daru</span>, je potřeba vyplnit nezbytné údaje. Na potvrzení o daru musí být dle požadavků finančního úřadu uvedeno: <br>- <span style=\"text-decoration-line: underline;\">jméno a příjmení dárce </span><br>- a jeho <span style=\"text-decoration-line: underline;\">trvalá adresa</span>.\n<br><br>Pro spolehlivou identifikaci daru také potřebujeme znát <span style=\"text-decoration-line: underline;\">číslo účtu dárce</span>. <br>Abychom Vám mohli zaslat potvrzení o daru elektronicky, potřebujeme Vaši <span style=\"text-decoration-line: underline;\">e-mailovou adresu</span>. <br><br>Děkujeme za pochopení a těšíme se na jakoukoli formu spolupráce.<br>Váš Vidžín<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#dbd6f3","paddingTop":51,"paddingBottom":46}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" style={{"marginTop":0}} content={"Odkazy"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":334,"marginTop":30}} content={"<a href=\"/nf-vidzin#nfvidzinuvod\" style=\"color: var(--color-custom-1);\">Nadační fond Vidžín</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--22" style={{"marginTop":19}} content={"<a href=\"/#prohlidky\" style=\"color: var(--color-custom-1);\">Prohlídky zámku</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" content={"Zámek Vidžín"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":311,"marginTop":21}} content={"<span style=\"color: var(--color-custom-1);\">Nadační fond Vidžín\n<br>Vidžín č.8, 330 40 Úterý<br><span style=\"font-weight: bold;\">tel:&nbsp;602 625 691</span></span>"}>
              </Text>

              <Image className="--center" style={{"maxWidth":196,"marginTop":27,"paddingLeft":33}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=1400x_.png 1400w"} alt={"logo zámek Vidžín"} src={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" style={{"marginTop":0}} content={"Sociální sítě"}>
              </Title>

              <Image style={{"maxWidth":40,"paddingLeft":0,"marginTop":31}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png 350w"} alt={"facebook"} src={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.facebook.com/zameckafaravidzin"} url={"https://www.facebook.com/zameckafaravidzin"}>
              </Image>

              <Image style={{"maxWidth":38,"paddingLeft":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/zamekvidzin/"} url={"https://www.instagram.com/zamekvidzin/"}>
              </Image>

              <Image style={{"maxWidth":42}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.youtube.com/watch?v=aIwoNcyrEC4"} url={"https://www.youtube.com/watch?v=aIwoNcyrEC4"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}